








































import { Vue, Prop, Component } from 'vue-property-decorator';
import EdProfile from '@/components/edBusiness/mobile/account/EdProfile.vue';
import InsNotification from '@/components/business/mobile/account/InsNotification.vue';
import InsOrderList from '@/components/business/mobile/account/InsOrderList.vue';
import InsMyFavorite from '@/components/business/mobile/account/InsMyFavorite.vue';
import InsMemberPoints from '@/components/business/mobile/account/InsPoints.vue';
import InsMyCoupon from '@/components/business/mobile/account/InsMyCoupon.vue';
@Component({ components: { EdProfile, InsNotification, InsOrderList, InsMyFavorite, InsMemberPoints, InsMyCoupon } })
export default class InsMemberCentral extends Vue {
  private TotalPoints:string = '0';
  private active = 'EdProfile';
  To (path) {
    if (this.active === path) return;
    this.$ShowLayer();
    this.active = path;
  }
  // 获取会员总积分
  getMemberTotal () {
    this.$Api.pointsApi.GetMemberTotalPointsInfo().then((result) => {
      this.TotalPoints = result.data.TotalPoints;
    });
  }
}
