import { render, staticRenderFns } from "./EdProfile.vue?vue&type=template&id=6523fc84&scoped=true&"
import script from "./EdProfile.vue?vue&type=script&lang=ts&"
export * from "./EdProfile.vue?vue&type=script&lang=ts&"
import style0 from "./EdProfile.vue?vue&type=style&index=0&id=6523fc84&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6523fc84",
  null
  
)

export default component.exports