













import { Vue, Prop, Component } from 'vue-property-decorator';
import MyProfile from '@/components/edBusiness/pc/account/InsProfileMain.vue';
import Password from '@/components/edBusiness/pc/account/InsPassword.vue';
import DeliveryAddress from '@/components/business/mobile/account/InsDeliveryAddress.vue';
@Component({ components: { MyProfile, Password, DeliveryAddress } })
export default class EdProfile extends Vue {
  private profileTo = 'MyProfile';
  To (to) {
    this.profileTo = to;
  }
}
